import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

// Images
import imgLogo from 'img/logo.svg'
import twitter from '../../img/twitter.svg'
import linkedin from '../../img/linkedin.svg'

// Components
import CustomLink from 'components/CustomLink'

// CSS
import './Header.scss'

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true);

  const { headerItems } = useStaticQuery(graphql`
  {
    headerItems: wordpressMenusMenusItems(term_id: { eq: 2 }) {
      name
      items {
        title
        url
      }
    }
  }
`)

const headerContent = headerItems

  return (
    <nav className="header position-relative">
      <div className="navbar-container container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={imgLogo} alt="" />
          </Link>
        </div>

        <button type="button" onClick={() => setCollapsed(!isCollapsed)} className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}>
          {isCollapsed ? (
            <>
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
            </>
          ) : (
            <div className="navbar-collapse-button-close" />
          )}
        </button>

        <HeaderMenuMobile
          items={headerContent.items}
          isCollapsed={isCollapsed}
          setCollapse={setCollapsed}
        />
        <HeaderMenuDesktop items={headerContent.items} />
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ isCollapsed, items }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems items={items} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ items }) => (
  <ul className='navbar-items d-none d-lg-flex'>
    <HeaderMenuItems items={items} />
  </ul>
)

const HeaderMenuItems = ({ items }) => (
  <>
    {items.map((item, index) => (
      <li key={index}>
        <span
          className={`header-a${
            item.child_items !== null ? ' has-children' : ''
          }`}
        >
          <Link to={item.url}><span dangerouslySetInnerHTML={{ __html: item.title }} /></Link>
        </span>
      </li>
    ))}
  </>
)

export default Header
