import React from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'

// CSS
import './Footer.scss'

// Images
import logoFooter from 'img/logo.svg'
import arrowMenu from 'img/arrow-menu.svg'
import mailIcon from 'img/mail-icon-kavelruil.svg'
import phoneIcon from 'img/phone-icon-kavelruil.svg'
import twitterIcon from 'img/twitter-icon.svg'
import linkedinIcon from 'img/linkedin-icon.svg'

const Footer = () => {
  const { sitemap, kavelruil } = useStaticQuery(graphql`
    {
      sitemap: wordpressMenusMenusItems(term_id: { eq: 3 }) {
        name
        items {
          title
          url
        }
      }
      kavelruil: wordpressMenusMenusItems(term_id: { eq: 4 }) {
        name
        items {
          title
          url
        }
      }
    }
  `)

  return (
    <div className="footer pb-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="widgettitle">
              <img src={arrowMenu} width="18" alt="arrow" />
              Menu
            </div>
            <ul className="footer-menu">
              <SiteMapItems items={sitemap.items} />
            </ul>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widgettitle">
              <img src={arrowMenu} width="18" alt="arrow" />
              Kavelruil
            </div>
            <ul className="footer-menu">
              <SiteMapItems items={kavelruil.items} />
            </ul>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widgettitle">
              <img src={arrowMenu} width="18" alt="arrow" />
              Contact
            </div>
            <p className="name">prof. mr. J.W.A. (Jeroen) Rheinfeld</p>
            <p>
              <img src={mailIcon} width="32" alt="Mail ons" />
              <a href="mailto:info@kavelruil.net">info@kavelruil.net</a>
            </p>
            <p>
              <img src={phoneIcon} width="32" alt="Mail ons" />
              <a href="tel:+31641110443">06 41 11 04 43</a>
            </p>
            <p>
              <img src={twitterIcon} width="32" alt="Mail ons" />
              <a href="https://twitter.com/JeroenRheinfeld" target="_blank" rel="noopener noreferrer">@JeroenRheinfeld</a>
            </p>
            <p>
              <img src={linkedinIcon} width="32" alt="Mail ons" />
              <a href="https://www.linkedin.com/in/jeroenrheinfeld/" target="_blank" rel="noopener noreferrer">Linkedin-profiel</a>
            </p>
          </div>
          <div className="col-lg-3 footer-logo text-center">
            <img src={logoFooter} width="150" alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

const SiteMapItems = ({ items }) => (
  <>
    {items.map((item, index) => (
      <li key={index}>
        <span
          className={`header-a${
            item.child_items !== null ? ' has-children' : ''
          }`}
        >
          <Link to={item.url}>
            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </Link>
        </span>
      </li>
    ))}
  </>
)

export default Footer
